import React from 'react'
import Layout from '../components/Layout.js'
import Container from '../components/Container.js'
import Footer from '../components/Footer.js'
import styles from '../templates/ContentPageTemplate.module.sass'

const NotFoundPage = () => (
  <Layout isHeaderFilled>
    <div className={styles.contentPage}>
      <Container>
        <div className={styles.runningText}>
          <h1>Not found</h1>
          <div>
            <p>The page you are looking for does not exist.</p>
            <p>Perhaps it has been deleted?</p>
          </div>
        </div>
      </Container>
    </div>
    <Footer />
  </Layout>
)

export default NotFoundPage
